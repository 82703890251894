<template>
  <div class="container">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>
    <!-- <el-row class="payment">
      <el-col :offset="1" :span="7" class="dataTotal stu">
        <el-image :src="require('../../assets/imgs/图层 31.png')" alt="" />
        <div class="used">
          <div>{{ payTotal.settleCash }}</div>
          <div>已销金额</div>
        </div>
      </el-col>
      <el-col :offset="1" :span="7" class="dataTotal stu">
        <el-image :src="require('../../assets/imgs/图层 32.png')" alt="" />
        <div class="unused">
          <div>{{ payTotal.noSettleCash }}</div>
          <div>待消金额</div>
        </div>
      </el-col>
      <el-col :offset="1" :span="7" class="dataTotal stu">
        <el-image :src="require('../../assets/imgs/图层 33.png')" alt="" />
        <div class="total">
          <div>{{ payTotal.cashTotal }}</div>
          <div>收费总额</div>
        </div>
      </el-col>
    </el-row> -->
    <!-- <el-row>
      <el-tabs v-model="activeChartName" @tab-click="chartTab">
        <el-tab-pane label="校外" name="first">
          <all-echart ref="chart1" :chartType="'rings'"></all-echart>
        </el-tab-pane>
        <el-tab-pane label="课后" name="second">
          <all-echart ref="chart2" :chartType="'rings'"></all-echart>
        </el-tab-pane>
      </el-tabs>
    </el-row> -->
    <el-row>
      <el-tabs v-model="activeTableName" @tab-click="listTab">
        <!-- 缴费订单 -->
        <el-tab-pane label="兑换记录" name="first">
          <!-- <el-row class="search">
            <label for="contacts">姓名</label>
            <el-input size="small" v-model="nick_name" clearable></el-input>
            <label for="nick_name">单号</label>
            <el-input size="small" v-model="flowing_no" clearable></el-input>
            <label for="nick_name">会员卡号</label>
            <el-input size="small" v-model="card_id" clearable></el-input>
            <label for="timeRange">时间</label>
            <el-date-picker size="small" v-model="timeRange" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期"></el-date-picker>
            <label for="course_name">商品名称</label>
            <el-input size="small" v-model="title"></el-input>
            <el-button size="small" @click="search('url1')">查询</el-button>
          </el-row> -->
          <!--  -->
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="姓名">
              <el-input size="small" v-model="nick_name" clearable></el-input>
            </el-form-item>
            <el-form-item label="单号">
              <el-input size="small" v-model="flowing_no" clearable></el-input>
            </el-form-item>
            <el-form-item label="会员卡号">
              <el-input size="small" v-model="card_id" clearable></el-input>
            </el-form-item>
            <el-form-item label="商品名称">
              <el-input size="small" v-model="title"></el-input>
            </el-form-item>
            <el-form-item label="时间">
              <el-date-picker
                size="small"
                v-model="timeRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button size="small" @click="search('url1')">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button
                size="small"
                style="color: skyblue"
                @click="downloadPayList('url1')"
                >导出缴费流水</el-button
              >
            </el-form-item>
          </el-form>

          <!-- <el-button size="small" style="float: right; color: skyblue" @click="downloadPayList('url1')">导出缴费流水</el-button> -->
          <el-table
            :data="payList"
            :fit="true"
            :cell-style="{ 'text-align': 'center' }"
            v-loading="is_loading"
            :header-cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column label="ID" prop="id"></el-table-column>
            <el-table-column label="订单号" prop="flowing_no"></el-table-column>
            <el-table-column
              label="支付会员卡号"
              prop="card_id"
            ></el-table-column>
            <el-table-column
              label="商品名称"
              prop="map.masterSetPriceEntity.title"
            ></el-table-column>
            <el-table-column label="权益点" prop="point"></el-table-column>
            <!-- <el-table-column
              label="所属机构"
              prop="map.mechanism"
            ></el-table-column> -->
            <el-table-column
              label="用户昵称"
              prop="map.userinfo.nick_name"
            ></el-table-column>
            <el-table-column
              label="手机号"
              prop="map.userinfo.mobile"
            ></el-table-column>
            <el-table-column
              label="交易时间"
              prop="create_time"
              width="200px"
            ></el-table-column>
          </el-table>
          <!-- 分页 -->
          <!-- <el-pagination
            :total="payListTotal"
            :current-page="currentPage"
            @current-change="payPageChange"
          >
          </el-pagination> -->
          <paging-fy
            @currentPageChange="payPageChange"
            :currentPage="currentPage"
            :total="payListTotal"
          ></paging-fy>
        </el-tab-pane>
        <!-- 消课订单 -->
        <el-tab-pane label="核销记录" name="second">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="姓名">
              <el-input size="small" v-model="nick_name" clearable></el-input>
            </el-form-item>
            <el-form-item label="单号">
              <el-input size="small" v-model="flowing_no" clearable></el-input>
            </el-form-item>

            <el-form-item label="商品名称">
              <el-select
                filterable
                clearable
                v-model="studycard_id"
                remote
                :remote-method="searchCouName"
              >
                <el-option
                  v-for="(option, index) in studycard_list"
                  :key="index"
                  :value="option.id"
                  :label="option.title"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属机构">
              <el-select
                filterable
                clearable
                v-model="mechanism_id"
                remote
                :remote-method="searchOrgName"
              >
                <el-option
                  v-for="(option, index) in mechanism_name_list"
                  :key="index"
                  :value="option.id"
                  :label="option.mechanism_name"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button @click="search('url2')">查询</el-button>
            </el-form-item>
            <el-form-item>
              <el-button style="color: skyblue" @click="downloadPayList('url2')"
                >导出缴费流水</el-button
              >
            </el-form-item>
          </el-form>

          <!-- <el-row class="main">
            <label for="contacts">姓名</label>
            <el-input v-model="nick_name" clearable></el-input>
            <label for="nick_name">单号</label>
            <el-input v-model="flowing_no" clearable></el-input>
            <label for="course_name">商品名称</label>
            <el-select
              filterable
              clearable
              v-model="studycard_id"
              remote
              :remote-method="searchCouName"
            >
              <el-option
                v-for="(option, index) in studycard_list"
                :key="index"
                :value="option.id"
                :label="option.title"
              ></el-option>
            </el-select>
            <label for="mechanism_name">所属机构</label>
            <el-select
              filterable
              clearable
              v-model="mechanism_id"
              remote
              :remote-method="searchOrgName"
            >
              <el-option
                v-for="(option, index) in mechanism_name_list"
                :key="index"
                :value="option.id"
                :label="option.mechanism_name"
              ></el-option>
            </el-select>

            <el-button @click="search('url2')">查询</el-button>
            <el-button
              style="float: right; color: skyblue"
              @click="downloadPayList('url2')"
              >导出缴费流水</el-button
            >
          </el-row> -->
          <el-table
            :data="payList"
            :fit="true"
            v-loading="is_loading"
            cell-style="text-align:center"
            :header-cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column label="ID" prop="id"></el-table-column>
            <el-table-column
              label="机构名称"
              prop="map.mechanismEntity.mechanism_name"
            ></el-table-column>
            <el-table-column
              label="交易单号"
              width="250px"
              prop="flowing_no"
            ></el-table-column>
            <el-table-column
              label="商品名称"
              prop="map.masterSetPriceEntity.title"
            ></el-table-column>
            <el-table-column label="权益点" prop="points"></el-table-column>
            <el-table-column
              label="姓名"
              prop="map.userinfo.nick_name"
            ></el-table-column>
            <el-table-column
              label="手机号"
              prop="map.userinfo.mobile"
            ></el-table-column>
            <el-table-column
              label="交易时间"
              prop="rcharge_time"
            ></el-table-column>
            <el-table-column label="缴费方式" prop="pay_type"></el-table-column>
          </el-table>
          <!-- 分页 -->
          <paging-fy
            @currentPageChange="finPageChange"
            :currentPage="currentPage"
            :total="payListTotal"
          ></paging-fy>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: { firstNav: "机构管理中心", secondNav: "订单管理" },
      // activeChartName: "first",
      activeTableName: "first",
      currentPage: 1,
      is_loading: true,
      // 查询
      flowing_no: "",
      studycard_id: null,
      studycard_list: [],
      timeRange: null,
      nick_name: "",
      card_id: null,
      title: null,
      mechanism_id: null,
      mechanism_name_list: [],
      payTotal: { settleCash: 0, cashTotal: 0, noSettleCash: 0 },
      payList: [],
      payListTotal: 0,
    };
  },
  methods: {
    // getPayTotal() {
    //   let url = "/user/rechargerecord/querySettleCount";
    //   this.$axios
    //     .get(url, {
    //       params: {},
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       this.payTotal = res.data.data;
    //     });
    // },
    searchCouName(query) {
      let url = "/user/masterSetPrice/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: "mechanism_offline",
            categories: "体育运动",
            source: "体育宝",
            pageSize: 10,
            // is_school: false,
            currentPage: 1,
            title: typeof query == "string" ? query : null,
            // master_id: this.master_id? this.master_id:null,
            mechanism_id: this.mechanism_id ? this.mechanism_id : null,
          },
        })
        .then((res) => {
          this.studycard_list = res.data.data.rows;
        })
        .catch((err) => {});
    },
    getPayList(url) {
      console.log(this.timeRange);
      this.is_loading = true;
      let urls = {
        url2: "/user/rechargerecord/queryByMessage",
        url1: "/user/userPointLog/queryByMessage",
      };
      let data = {
        url2: {
          pageSize: 10,
          currentPage: this.currentPage,
          start_time: this.timeRange
            ? this.timeRange[0].Format("yyyy-MM-dd hh:mm:ss")
            : null,
          update_time: this.timeRange
            ? this.timeRange[1].Format("yyyy-MM-dd hh:mm:ss")
            : null,
          status: 3,
          nick_name: this.nick_name ? this.nick_name : null,
          rcharge_type: "study_card",
        },
        url1: {
          // mechanism_id:
          type: "换课",
          status: 2,
          currentPage: this.currentPage,
          pageSize: 10,
          nick_name: this.nick_name ? this.nick_name : null,
          flowing_no: this.flowing_no ? this.flowing_no : null,
          start_time: this.timeRange
            ? this.timeRange[0].Format("yyyy-MM-dd hh:mm:ss")
            : null,
          end_time: this.timeRange
            ? this.timeRange[1].Format("yyyy-MM-dd hh:mm:ss")
            : null,
          title: this.title ? this.title : null,
          card_id: this.card_id ? this.card_id : null,
        },
      };
      this.$axios
        .get(urls[url], {
          params: {
            ...data[url],
          },
        })
        .then((res) => {
          this.payList = res.data.data.rows;
          this.payListTotal = res.data.data.total;
          this.is_loading = false;
        });
    },
    search(status) {
      this.currentPage = 1;
      this.getPayList(status);
    },
    downloadPayList(state) {
      this.$axios
        .request({
          method: "get",
          url: "/user/rechargerecord/exportExcel",
          responseType: "blob",
          params: {
            status: state == 1 ? 2 : state,
            pageSize: this.payListTotal,
            currentPage: 1,
            finished: true,
            is_school: false,
            is_settlement: state == 1 ? true : state == 2 ? false : null,
            mechanism_id: this.mechanism_id ? this.mechanism_id : null,
            nick_name: this.nick_name ? this.nick_name : null,
            studycard_id: this.studycard_id ? this.studycard_id : null,
          },
        })
        .then((res) => {
          console.log("导出", res);
          const link = document.createElement("a");
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          link.style.display = "none";
          //设置连接
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          //模拟点击事件
          link.click();
        });
    },

    // getSchPayment() {
    //   this.$refs.chart2.loading();

    //   let url = "/user/rechargerecord/queryYesterdayCount";
    //   this.$axios
    //     .get(url, {
    //       parmas: {
    //         is_school: true,
    //       },
    //     })
    //     .then((res) => {
    //       let cancelMap = res.data.data.cancelMap;
    //       let payMap = res.data.data.payMap;
    //       let temp = [];
    //       temp.push(["缴费管理", "昨日消课金额", "昨日退款金额"]);

    //       for (let item in payMap) {
    //         if (item != "total") {
    //           temp.push([item, payMap[item], cancelMap[item]]);
    //         }
    //       }
    //       console.log(temp);
    //       this.$refs.chart2.drawRings({
    //         total: [payMap.total, cancelMap.total],
    //         data: temp,
    //       });
    //     });
    // },
    // getOutPayment() {
    //   this.$refs.chart1.loading();

    //   let url = "/user/rechargerecord/queryYesterdayCount";
    //   this.$axios
    //     .get(url, {
    //       parmas: {
    //         is_school: false,
    //       },
    //     })
    //     .then((res) => {
    //       let cancelMap = res.data.data.cancelMap;
    //       let payMap = res.data.data.payMap;
    //       let temp = [];
    //       temp.push(["缴费管理", "昨日消课金额", "昨日退款金额"]);

    //       for (let item in cancelMap) {
    //         if (item != "total") {
    //           temp.push([item, payMap[item], cancelMap[item]]);
    //         }
    //       }
    //       this.$refs.chart1.drawRings({
    //         total: [payMap.total, cancelMap.total],
    //         data: temp,
    //       });
    //     });
    // },
    searchOrgName(query) {
      let url = "/user/mastermechanism/queryMechanismByType";
      this.$axios
        .get(url, {
          params: {
            type: "teach_paypal",
            mechanism_name: query,
            pageSize: 10,
            currentPage: 1,
            status: 2,
          },
        })
        .then((res) => {
          // console.log(res);
          this.mechanism_name_list = res.data.data;
        });
    },
    payPageChange(v) {
      this.currentPage = v;
      this.getPayList("url1");
    },
    finPageChange(v) {
      this.currentPage = v;
      this.getPayList("url2");
    },
    // refPageChange(v) {
    //   this.currentPage = v
    //   this.getPayList(3)
    // },
    chartTab(tab, event) {
      if (tab.index == 0) {
        this.getOutPayment();
      } else if (tab.index == 1) {
        this.getSchPayment();
      }
    },
    listTab(tab, event) {
      this.flowing_no = "";
      this.studycard_id = null;
      this.nick_name = "";
      this.mechanism_name = "";
      this.currentPage = 1;

      if (tab.index == 0) {
        this.getPayList("url1");
      } else if (tab.index == 1) {
        this.getPayList("url2");
      }
    },
  },
  mounted() {
    // this.getOutPayment();
    // this.getPayTotal();
    this.getPayList("url1");
  },
};
</script>

<style lang="less">
.payment.el-row .el-col {
  display: flex;
  height: 10rem;
  box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.07);
  padding: 1rem 2.5rem;
}
.payment.el-row .el-col div {
  margin-right: 3rem;
}
.el-row.payment {
  margin-bottom: 3rem;
}
.el-row.payment .el-col div div:first-child {
  font-size: 3rem;
}
.el-row.payment .el-col div.used div:first-child {
  color: #4fc5ea;
}
.el-row.payment .el-col div.unused div:first-child {
  color: #f44c4c;
}
.el-row.payment .el-col div.total div:first-child {
  color: #6c6fbf;
}
.payment .used,
.payment .unused,
.payment .total {
  display: flex;
  height: inherit;
  flex-direction: column;
  align-content: center;
}
.main .el-input {
  width: inherit;
}
.search {
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  align-items: center;
}
/* .search > *:not(:last-child) {
  margin-right: 0.5rem;
} */
</style>
